/** @format */

import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useRef, useState, useEffect } from 'react';
import prompts from '../assets/data/chat/prompts.json';

const ChatTip = (props) => {
	const userData = useSelector((state) => state.user);

	const [tipMessage, setTipMessage] = useState('');
	const [hideChatTip, setHideChatTip] = useState(false);

	const container = useRef();

	useEffect(() => {
		setHideChatTip(false);

		if (userData.chatHistory.length <= 1) {
			container.current.classList.add('fadeIn');
		} else {
			container.current.classList.replace('fadeIn', 'fadeOut');
		}

		setTimeout(() => {
			let currentMessage = userData.chatHistory[0];
			if (userData.chatHistory.length > 0) {
				currentMessage = userData.chatHistory[userData.chatHistory.length - 1];
				/*if (currentMessage.user === 'welcome') {
					setTipMessage(currentMessage.popup);
					container.current.classList.replace('fadeOut', 'fadeIn');
				}*/
			} else {
				let overviewPrompt = prompts.filter((a) => a.screenID === 'overview');

				let newMessage = {
					user: 'overview',
					text: overviewPrompt[0].scenarios[0].prompts[0].question,
					itemId: 'overview',
				};
				currentMessage = overviewPrompt[0].scenarios[0].prompts[0];
			}

			setTipMessage(currentMessage.popup);
			container.current.classList.replace('fadeOut', 'fadeIn');
		}, 150);
	}, [userData.chatHistory]);

	const hideChatTipInstance = () => {
		setHideChatTip(true);
	};

	return (
		<div
			className={`chat-tip fadeOut ${hideChatTip ? 'hidden' : ''} ${
				props.pageType === 'overview' ? 'on-overview' : ''
			}`}
			ref={container}
		>
			<button
				className='close-btn'
				onClick={hideChatTipInstance}
			>
				<CloseIcon />
			</button>
			<div
				role='button'
				onClick={props.open}
				className='load-prompt'
				dangerouslySetInnerHTML={{
					__html: tipMessage,
				}}
			/>
			<a
				role='button'
				onClick={props.open}
			>
				Chat with me
			</a>
			<a
				role='button'
				className='link'
				onClick={(e) => {
					props.close(e);
				}}
			>
				Hide tutor alerts for this assignment
			</a>
		</div>
	);
};

export default ChatTip;
