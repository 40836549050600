/** @format */

import React from 'react';
import ChatbotTabs from './ChatbotTabs';
import SettingsIcon from '@mui/icons-material/Settings';

import ModeToggle from './ModeToggle';
//placeholders
const ChatbotRail = (props) => {
	// pass in if drawer is open, and opendrawer function?
	return (
		<div className='chatbot-rail'>
			<div className='rail-header'>
				{/* <img
					src={menuIcon}
					alt={'AI tools'}
				/> */}
			</div>
			{/* Tabs? */}
			<ChatbotTabs
				drawerOpen={props.drawerOpen}
				openDrawer={(foo) => props.openDrawer(foo)}
				changeDrawerType={props.changeDrawerType}
			/>

			<SettingsIcon style={{ fill: '#616161', visibility: 'hidden' }} />
		</div>
	);
};
export default ChatbotRail;
